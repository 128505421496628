<section
  class="bg-img1 txt-center p-lr-15 p-tb-92"
  style="background-image: url('assets/images/bg-01.jpg')"
>
  <h2 class="ltext-105 cl0 txt-center">Blogs</h2>
</section>
<section class="sec-blog bg0 p-t-60 p-b-90">
  <div class="container">
    <div class="row">
      <div class="col-sm-6 col-md-4 p-b-40" *ngFor="let item of listblog">
        <div class="blog-item">
          <div class="hov-img0">
            <a [routerLink]="'/blog/' + item.id">
              <img
                src="https://ecolifeapi.developvn.click{{ item.image }}"
                alt="IMG-BLOG"
              />
            </a>
          </div>
          <div class="p-t-15">
            <div class="stext-107 flex-w p-b-14">
              <span class="m-r-3">
                <span class="cl4"> Đăng bởi </span>
                <span class="cl5">
                  {{ item.userName }}
                </span>
              </span>
              <span>
                <span class="cl4"> | Thời gian: </span>
                <span class="cl5">
                  {{ item.createdAt | date : "dd/MM/yyyy" }}
                </span>
              </span>
            </div>
            <h4 class="p-b-12">
              <a
                [routerLink]="['/blog', item.id]"
                class="mtext-101 cl2 hov-cl1 trans-04"
                (click)="storeItemInLocalStorage(item)"
              >
                {{ item.title }}
              </a>
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
