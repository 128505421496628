<section class="bg0 p-t-23">
  <div class="container">
    <div class="p-b-10">
      <h3 class="ltext-103 cl5">Sản phẩm mới</h3>
    </div>
    <p-carousel
      [value]="products"
      styleClass="custom-carousel"
      [numVisible]="4"
      [numScroll]="1"
      [circular]="true"
      [autoplayInterval]="2000"
      [responsiveOptions]="responsiveOptions"
    >
      <ng-template let-product pTemplate="item">
        <div style="padding-right: 15px; padding-left: 15px; width: 100%">
          <div class="block2">
            <div class="block2-pic hov-img0">
              <img
                src="https://ecolifeapi.developvn.click{{ product.image }}"
                style="width: 180px; height: 200px"
                alt="IMG-PRODUCT"
              />
            </div>
            <div class="block2-txt flex-w flex-t p-t-14">
              <div class="block2-txt-child1 flex-col-l">
                <a
                  [routerLink]="'/product/' + product.id"
                  class="stext-104 cl4 hov-cl1 trans-04 js-name-b2 p-b-6"
                >
                  {{ product.name }}
                </a>
                <span class="stext-105 cl3">
                  {{ product.price | number }} VNĐ
                </span>
              </div>
              <div class="block2-txt-child2 flex-r p-t-3">
                <a
                  *ngIf="product.like == null"
                  (click)="like(product.id)"
                  class="btn-addwish-b2 dis-block pos-relative js-addwish-b2"
                >
                  <img
                    class="icon-heart1 dis-block trans-04"
                    src="../../../assets/images/icons/icon-heart-01.png"
                    alt="ICON"
                  />
                </a>
                <a
                  *ngIf="product.like == 1"
                  (click)="like(product.id)"
                  class="btn-addwish-b2 dis-block pos-relative js-addwish-b2"
                >
                  <img
                    class="icon-heart1 dis-block trans-04"
                    src="../../../assets/images/icons/icon-heart-02.png"
                    alt="ICON"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </p-carousel>
    <!-- Load more
        <div class="flex-c-m flex-w w-full p-t-45">
            <a href="#" class="flex-c-m stext-101 cl5 size-103 bg2 bor1 hov-btn1 p-lr-15 trans-04">
                Load More
            </a>
        </div> -->
  </div>
</section>
<section class="bg0 p-t-23 p-b-140 mb-5">
  <div class="container">
    <div class="p-b-10">
      <h3 class="ltext-103 cl5">Danh sách sản phẩm</h3>
    </div>
    <div class="flex-w flex-sb-m p-b-52">
      <div class="flex-w flex-l-m filter-tope-group m-tb-10">
        <button
          class="stext-106 cl6 hov1 bor3 trans-04 m-r-32 m-tb-5 how-active1"
          data-filter="*"
        >
          Tất cả sản phẩm
        </button>
        <button
          class="stext-106 cl6 hov1 bor3 trans-04 m-r-32 m-tb-5"
          data-filter=".women"
        >
          Nữ
        </button>
        <button
          class="stext-106 cl6 hov1 bor3 trans-04 m-r-32 m-tb-5"
          data-filter=".men"
        >
          Nam
        </button>
      </div>
      <div class="flex-w flex-c-m m-tb-10">
        <div
          class="flex-c-m stext-106 cl6 size-104 bor4 pointer hov-btn3 trans-04 m-r-8 m-tb-4 js-show-filter"
        >
          <i
            class="icon-filter cl2 m-r-6 fs-15 trans-04 zmdi zmdi-filter-list"
          ></i>
          <i
            class="icon-close-filter cl2 m-r-6 fs-15 trans-04 zmdi zmdi-close dis-none"
          ></i>
          Bộ lọc
        </div>
        <div
          class="flex-c-m stext-106 cl6 size-105 bor4 pointer hov-btn3 trans-04 m-tb-4 js-show-search"
        >
          <i class="icon-search cl2 m-r-6 fs-15 trans-04 zmdi zmdi-search"></i>
          <i
            class="icon-close-search cl2 m-r-6 fs-15 trans-04 zmdi zmdi-close dis-none"
          ></i>
          Tìm kiếm
        </div>
      </div>
      <!-- Search product -->
      <div class="dis-none panel-search w-full p-t-10 p-b-15">
        <div class="bor8 dis-flex p-l-15">
          <button class="size-113 flex-c-m fs-16 cl2 hov-cl1 trans-04">
            <i class="zmdi zmdi-search"></i>
          </button>
          <input
            class="mtext-107 cl2 size-114 plh2 p-r-15"
            type="text"
            [(ngModel)]="searchText"
            autocomplete="off"
            name="search-product"
            placeholder="Tìm kiếm"
          />
        </div>
      </div>
      <!-- Filter -->
      <div class="dis-none panel-filter w-full p-t-10">
        <div class="wrap-filter flex-w bg6 w-full p-lr-40 p-t-27 p-lr-15-sm">
          <div class="filter-col2 p-r-15 p-b-27">
            <div class="mtext-102 cl2 p-b-15">Giá</div>
            <ul>
              <li class="p-b-6">
                <button
                  (click)="searchTheoGia(0, 999999999, 1)"
                  *ngIf="chose_gia == 1"
                  class="filter-link stext-106 trans-04 filter-link-active"
                >
                  Tất cả
                </button>
                <button
                  (click)="searchTheoGia(0, 999999999, 1)"
                  *ngIf="chose_gia != 1"
                  class="filter-link stext-106 trans-04"
                >
                  Tất cả
                </button>
              </li>
              <li class="p-b-6">
                <button
                  (click)="searchTheoGia(100000, 300000, 2)"
                  *ngIf="chose_gia == 2"
                  class="filter-link stext-106 trans-04 filter-link-active"
                >
                  {{ 100000 | number }} - {{ 300000 | number }} VNĐ
                </button>
                <button
                  (click)="searchTheoGia(100000, 300000, 2)"
                  *ngIf="chose_gia != 2"
                  class="filter-link stext-106 trans-04"
                >
                  {{ 100000 | number }} - {{ 300000 | number }} VNĐ
                </button>
              </li>
              <li class="p-b-6">
                <button
                  (click)="searchTheoGia(300000, 400000, 3)"
                  *ngIf="chose_gia == 3"
                  class="filter-link stext-106 trans-04 filter-link-active"
                >
                  {{ 300000 | number }} - {{ 400000 | number }} VNĐ
                </button>
                <button
                  (click)="searchTheoGia(300000, 400000, 3)"
                  *ngIf="chose_gia != 3"
                  class="filter-link stext-106 trans-04"
                >
                  {{ 300000 | number }} - {{ 400000 | number }} VNĐ
                </button>
              </li>
              <li class="p-b-6">
                <button
                  (click)="searchTheoGia(400000, 500000, 4)"
                  *ngIf="chose_gia == 4"
                  class="filter-link stext-106 trans-04 filter-link-active"
                >
                  {{ 400000 | number }} - {{ 500000 | number }} VNĐ
                </button>
                <button
                  (click)="searchTheoGia(400000, 500000, 4)"
                  *ngIf="chose_gia != 4"
                  class="filter-link stext-106 trans-04"
                >
                  {{ 400000 | number }} - {{ 500000 | number }} VNĐ
                </button>
              </li>
              <li class="p-b-6">
                <button
                  (click)="searchTheoGia(500000, 999999999, 5)"
                  *ngIf="chose_gia == 5"
                  class="filter-link stext-106 trans-04 filter-link-active"
                >
                  {{ 500000 | number }} +
                </button>
                <button
                  (click)="searchTheoGia(500000, 999999999, 5)"
                  *ngIf="chose_gia != 5"
                  class="filter-link stext-106 trans-04"
                >
                  {{ 500000 | number }} +
                </button>
              </li>
            </ul>
          </div>
          <!-- <div class="filter-col3 p-r-15 p-b-27">
                        <div class="mtext-102 cl2 p-b-15">
                            Màu
                        </div>
                        <ul>
                            <li class="p-b-6">
                                <span class="fs-15 lh-12 m-r-6" style="color: #222;">
                                    <i class="zmdi zmdi-circle"></i>
                                </span>
                                <button *ngIf="chose_mau==1" (click)="searchthemau('Đen',1)"
                                    class="filter-link stext-106 trans-04 filter-link-active">
                                    Đen
                                </button>
                                <button *ngIf="chose_mau!=1" (click)="searchthemau('Đen',1)"
                                    class="filter-link stext-106 trans-04 ">
                                    Đen
                                </button>
                            </li>
                            <li class="p-b-6">
                                <span class="fs-15 lh-12 m-r-6" style="color: #fdfdfd;">
                                    <i class="zmdi zmdi-circle"></i>
                                </span>
                                <button *ngIf="chose_mau==2" (click)="searchthemau('Trắng',2)"
                                    class="filter-link stext-106 trans-04 filter-link-active">
                                    Trắng
                                </button>
                                <button *ngIf="chose_mau!=2" (click)="searchthemau('Trắng',2)"
                                    class="filter-link stext-106 trans-04">
                                    Trắng
                                </button>
                            </li>
                            <li class="p-b-6">
                                <span class="fs-15 lh-12 m-r-6" style="color: yellow;">
                                    <i class="zmdi zmdi-circle"></i>
                                </span>
                                <button *ngIf="chose_mau==3" (click)="searchthemau('Vàng',3)"
                                    class="filter-link stext-106 trans-04 filter-link-active">
                                    Vàng
                                </button>
                                <button *ngIf="chose_mau!=3" (click)="searchthemau('Vàng',3)"
                                    class="filter-link stext-106 trans-04">
                                    Vàng
                                </button>
                            </li>
                            <li class="p-b-6">
                                <span class="fs-15 lh-12 m-r-6" style="color: purple;">
                                    <i class="zmdi zmdi-circle"></i>
                                </span>
                                <button *ngIf="chose_mau==4" (click)="searchthemau('Tím',4)"
                                    class="filter-link stext-106 trans-04 filter-link-active">
                                    Tím
                                </button>
                                <button *ngIf="chose_mau!=4" (click)="searchthemau('Tím',4)"
                                    class="filter-link stext-106 trans-04">
                                    Tím
                                </button>
                            </li>
                            <li class="p-b-6">
                                <span class="fs-15 lh-12 m-r-6" style="color: red;">
                                    <i class="zmdi zmdi-circle"></i>
                                </span>
                                <button *ngIf="chose_mau==5" (click)="searchthemau('Đỏ',5)"
                                    class="filter-link stext-106 trans-04 filter-link-active">
                                    Đỏ
                                </button>
                                <button *ngIf="chose_mau!=5" (click)="searchthemau('Đỏ',5)"
                                    class="filter-link stext-106 trans-04">
                                    Đỏ
                                </button>
                            </li>
                        </ul>
                    </div> -->
        </div>
      </div>
    </div>
    <div class="row isotope-grid" style="height: auto; max-height: 100%">
      <div
        *ngFor="let item of list_product_female | appFilter : searchText"
        appHighlight
        [searchedWord]="searchText"
        [content]="c"
        [classToApply]="'font-weight-bold'"
        [setTitle]="'true'"
        class="col-sm-6 col-md-4 col-lg-3 p-b-35 isotope-item women"
      >
        <!-- Block2 -->
        <div class="block2">
          <div class="block2-pic hov-img0">
            <img
              src="https://ecolifeapi.developvn.click{{ item.image }}"
              style="height: 200px; width: 180px"
              alt="IMG-PRODUCT"
            />
          </div>
          <div class="block2-txt flex-w flex-t p-t-14">
            <div class="block2-txt-child1 flex-col-l">
              <a
                [routerLink]="'/product/' + item.id"
                class="stext-104 cl4 hov-cl1 trans-04 js-name-b2 p-b-6"
              >
                {{ item.name }}
              </a>
              <span class="stext-105 cl3"> {{ item.price | number }} VNĐ </span>
            </div>
            <div class="block2-txt-child2 flex-r p-t-3">
              <a
                *ngIf="item.like == null"
                (click)="like(item.id)"
                class="btn-addwish-b2 dis-block pos-relative js-addwish-b2"
              >
                <img
                  class="icon-heart1 dis-block trans-04"
                  src="../../../assets/images/icons/icon-heart-01.png"
                  alt="ICON"
                />
              </a>
              <a
                *ngIf="item.like == 1"
                (click)="like(item.id)"
                class="btn-addwish-b2 dis-block pos-relative js-addwish-b2"
              >
                <img
                  class="icon-heart1 dis-block trans-04"
                  src="../../../assets/images/icons/icon-heart-02.png"
                  alt="ICON"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        *ngFor="let item of list_product_male | appFilter : searchText"
        appHighlight
        [searchedWord]="searchText"
        [content]="c"
        [classToApply]="'font-weight-bold'"
        [setTitle]="'true'"
        class="col-sm-6 col-md-4 col-lg-3 p-b-35 isotope-item men"
      >
        <!-- Block2 -->
        <div class="block2">
          <div class="block2-pic hov-img0">
            <img
              src="https://ecolifeapi.developvn.click{{ item.image }}"
              style="height: 200px; width: 180px"
              alt="IMG-PRODUCT"
            />
          </div>
          <div class="block2-txt flex-w flex-t p-t-14">
            <div class="block2-txt-child1 flex-col-l">
              <a
                [routerLink]="'/product/' + item.id"
                class="stext-104 cl4 hov-cl1 trans-04 js-name-b2 p-b-6"
              >
                {{ item.name }}
              </a>
              <span class="stext-105 cl3"> {{ item.price | number }} VNĐ </span>
            </div>
            <div class="block2-txt-child2 flex-r p-t-3">
              <a
                (click)="like(item.id)"
                class="btn-addwish-b2 dis-block pos-relative js-addwish-b2"
              >
                <img
                  class="icon-heart1 dis-block trans-04"
                  src="../../../assets/images/icons/icon-heart-01.png"
                  alt="ICON"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- <pagination-controls (pageChange)="p = $event"></pagination-controls> -->
    </div>
    <!-- Load more
        <div class="flex-c-m flex-w w-full p-t-45">
            <a href="#" class="flex-c-m stext-101 cl5 size-103 bg2 bor1 hov-btn1 p-lr-15 trans-04">
                Load More
            </a>
        </div> -->
  </div>
</section>
