<!-- breadcrumb -->
<div class="container">
  <div class="bread-crumb flex-w p-l-25 p-r-15 p-t-30 p-lr-0-lg">
    <a href="index.html" class="stext-109 cl8 hov-cl1 trans-04">
      Trang chủ
      <i class="fa fa-angle-right m-l-9 m-r-10" aria-hidden="true"></i>
    </a>
    <a href="product.html" class="stext-109 cl8 hov-cl1 trans-04">
      Nam
      <i class="fa fa-angle-right m-l-9 m-r-10" aria-hidden="true"></i>
    </a>
    <span class="stext-109 cl4">
      {{ this.product.name }}
    </span>
  </div>
</div>
<!-- Product Detail -->
<section class="sec-product-detail bg0 p-t-65 p-b-60">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-lg-7 p-b-30">
        <div class="p-l-25 p-r-30 p-lr-0-lg">
          <div class="wrap-slick3 flex-sb flex-w">
            <div class="wrap-slick3-dots"></div>
            <div class="wrap-slick3-arrows flex-sb-m flex-w"></div>
            <div class="slick3 gallery-lb">
              <div class="item-slick3">
                <div class="wrap-pic-w pos-relative">
                  <img
                    src="https://ecolifeapi.developvn.click{{ product.image }}"
                    alt="IMG-PRODUCT"
                  />
                  <a
                    class="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04"
                    href="https://ecolifeapi.developvn.click{{ product.image }}"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-5 p-b-30">
        <div class="p-r-50 p-t-5 p-lr-0-lg">
          <h4 class="mtext-105 cl2 js-name-detail p-b-14">
            {{ this.product.name }}
          </h4>
          <span class="mtext-106 cl2"> {{ product.price | number }} VNĐ </span>
          <!--  -->
          <div class="p-t-33">
            <div class="flex-w flex-r-m p-b-10">
              <div class="size-203 flex-c-m respon6">Màu sắc</div>
              <div class="size-204 respon6-next">
                <div class="rs1-select2 bor8 bg0">
                  <select
                    [(ngModel)]="selectMau"
                    (change)="onChangeMau($event.target.value)"
                    class="js-select2"
                    name="time"
                  >
                    <option value="null">Chọn màu</option>
                    <option *ngFor="let item of mau" [value]="item.colorName">
                      {{ item.colorName }}
                    </option>
                  </select>
                  <div class="dropDownSelect2"></div>
                </div>
              </div>
            </div>
            <div class="flex-w flex-r-m p-b-10">
              <div class="size-203 flex-c-m respon6">Size</div>
              <div class="size-204 respon6-next">
                <div class="rs1-select2 bor8 bg0">
                  <select
                    [(ngModel)]="selectSize"
                    class="js-select2"
                    name="time"
                  >
                    <option value="null">Chọn Size</option>
                    <option *ngFor="let item of size" [value]="item.size">
                      {{ item.size }}
                    </option>
                  </select>
                  <div class="dropDownSelect2"></div>
                </div>
              </div>
            </div>
            <div class="flex-w flex-r-m p-b-10">
              <div class="size-203 flex-c-m respon6">Số lượng tồn</div>
              <div class="size-204 respon6-next">
                {{ maxQty() }}
              </div>
            </div>
            <div class="flex-w flex-r-m p-b-10">
              <div class="size-204 flex-w flex-m respon6-next">
                <div class="wrap-num-product flex-w m-r-20 m-tb-10">
                  <button
                    (click)="soLuongTru()"
                    class="btn-num-product-down cl8 hov-btn3 trans-04 flex-c-m"
                  >
                    <i class="fs-16 zmdi zmdi-minus"></i>
                  </button>
                  <input
                    class="mtext-104 cl3 txt-center num-product"
                    type="number"
                    name="num-product"
                    [(ngModel)]="soLuong"
                    [value]="soLuong"
                  />
                  <button
                    (click)="soLuongCong()"
                    class="btn-num-product-up cl8 hov-btn3 trans-04 flex-c-m"
                  >
                    <i class="fs-16 zmdi zmdi-plus"></i>
                  </button>
                </div>
                <button
                  class="flex-c-m stext-101 cl0 size-101 bg1 bor1 hov-btn1 p-lr-15 trans-04 js-addcart-detail"
                  [disabled]="checkQty()"
                  (click)="addToCard(product)"
                >
                  Thêm vào giỏ hàng
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bor10 m-t-50 p-t-43 p-b-40">
      <!-- Tab01 -->
      <div class="tab01">
        <!-- Nav tabs -->
        <ul class="nav nav-tabs" role="tablist">
          <li class="nav-item p-b-10">
            <a
              class="nav-link active"
              data-toggle="tab"
              href="#reviews"
              role="tab"
              >Đánh giá</a
            >
          </li>
          <li class="nav-item p-b-10">
            <a class="nav-link" data-toggle="tab" href="#information" role="tab"
              >Mô tả</a
            >
          </li>
          <!-- <li class="nav-item p-b-10">
                        <a class="nav-link " data-toggle="tab" href="#reviews" role="tab">Hướng dẫn</a>
                    </li> -->
        </ul>
        <!-- Tab panes -->
        <div class="tab-content p-t-43">
          <!-- - -->
          <div class="tab-pane fade show active" id="reviews" role="tabpanel">
            <!-- <div class="how-pos2 p-lr-15-md">
                            <p class="stext-102 cl6">
                                <span [innerHtml]="testMarkup"></span>
                            </p>
                        </div> -->
            <div class="row">
              <div class="col-sm-10 col-md-8 col-lg-6 m-lr-auto">
                <div class="p-b-30 m-lr-15-sm">
                  <!-- Review -->
                  <div class="flex-w flex-t p-b-68">
                    <div class="size-207" *ngFor="let item of list_review">
                      <div class="flex-w flex-sb-m p-b-17">
                        <span class="mtext-107 cl2 p-r-20">
                          {{ item.userName }}
                        </span>
                      </div>
                      <p class="stext-102 cl6" *ngIf="item.rating == 1">
                        <i data-star="1"></i>
                      </p>
                      <p class="stext-102 cl6" *ngIf="item.rating == 2">
                        <i data-star="2"></i>
                      </p>
                      <p class="stext-102 cl6" *ngIf="item.rating == 3">
                        <i data-star="3"></i>
                      </p>
                      <p class="stext-102 cl6" *ngIf="item.rating == 4">
                        <i data-star="4"></i>
                      </p>
                      <p class="stext-102 cl6" *ngIf="item.rating == 5">
                        <i data-star="5"></i>
                      </p>
                      <p class="stext-102 cl6">
                        {{ item.content }}
                      </p>
                      <p class="stext-102 cl6">
                        {{ item.createdAt | date : "dd/MM/yyyy HH:mm" }}
                      </p>
                      <br />
                      <br />
                    </div>
                  </div>
                  <!-- Add review -->
                  <form class="w-full">
                    <h3 class="mtext-108 cl2 p-b-7">Thêm đánh giá</h3>
                    <div style="display: flex">
                      <div class="rate" style="padding: 0px">
                        <input
                          type="radio"
                          id="star5"
                          name="rate"
                          value="5"
                          [(ngModel)]="Rating"
                        />
                        <label for="star5" title="text">5 stars</label>
                        <input
                          type="radio"
                          id="star4"
                          name="rate"
                          value="4"
                          [(ngModel)]="Rating"
                        />
                        <label for="star4" title="text">4 stars</label>
                        <input
                          type="radio"
                          id="star3"
                          name="rate"
                          value="3"
                          [(ngModel)]="Rating"
                        />
                        <label for="star3" title="text">3 stars</label>
                        <input
                          type="radio"
                          id="star2"
                          name="rate"
                          value="2"
                          [(ngModel)]="Rating"
                        />
                        <label for="star2" title="text">2 stars</label>
                        <input
                          type="radio"
                          id="star1"
                          name="rate"
                          value="1"
                          [(ngModel)]="Rating"
                        />
                        <label for="star1" title="text">1 star</label>
                      </div>
                    </div>
                    <div class="row p-b-25">
                      <div class="col-12 p-b-5">
                        <label class="stext-102 cl3" for="review"
                          >Đánh giá của bạn</label
                        >
                        <textarea
                          [(ngModel)]="Content"
                          class="size-110 bor8 stext-102 cl2 p-lr-20 p-tb-10"
                          id="review"
                          name="review"
                        ></textarea>
                      </div>
                    </div>
                    <button
                      (click)="Review()"
                      class="flex-c-m stext-101 cl0 size-112 bg7 bor11 hov-btn3 p-lr-15 trans-04 m-b-10"
                    >
                      Gửi
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <!-- - -->
          <div class="tab-pane fade" id="information" role="tabpanel">
            <div class="row">
              <div
                class="col-sm-10 col-md-8 col-lg-10 m-lr-auto"
                style="font-family: Poppins-Regular; color: #888"
              >
                {{ product.description }}
                <div style="margin-top: 15px; color: #222">
                  Thương hiệu: {{ product.brandName }}
                </div>
                <div style="margin-top: 15px; color: #222">
                  Chất liệu: {{ product.material }}
                </div>
              </div>
            </div>
          </div>
          <!-- - -->
          <!-- <div class="tab-pane fade" id="description" role="tabpanel">
                        <div class="row">
                            <div class="col-sm-10 col-md-8 col-lg-6 m-lr-auto">
                                <div class="p-b-30 m-lr-15-sm">
                                    <span>Coming soon!</span>
                                </div>
                            </div>
                        </div>
                    </div> -->
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="bg6 flex-c-m flex-w size-302 m-t-73 p-tb-15">
        <span class="stext-107 cl6 p-lr-25">
            SKU: JAK-01
        </span>
        <span class="stext-107 cl6 p-lr-25">
            Categories: Jacket, Men
        </span>
    </div> -->
</section>
