<div class="bg-grey-lighter flex flex-col main" style="min-height: 50vh;">
    <div class="container max-w-sm mx-auto flex-1 flex flex-col items-center justify-center px-2">
        <div class="bg-white px-6 py-8 rounded shadow-md text-black w-full">
            <h1 class="mb-8 text-3xl text-center">Đặt lại mật khẩu</h1>
            <form name="resetPasswordForm" [formGroup]="resetPasswordForm" novalidate fxLayout="column">
                <div fxFlex="100%">
                    <mat-form-field [floatLabel]="'always'" appearance="outline" fxFlex="100%"
                        style="padding-left: 10px; padding-right: 10px; width: 100%">
                        <mat-label>Mật khẩu</mat-label>
                        <input matInput [type]="hide ? 'password' : 'text'" formControlName="password">
                        <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        <mat-error *ngIf="resetPasswordForm.get('password').hasError('required')">
                            Bắt buộc
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxFlex="100%">
                    <mat-form-field [floatLabel]="'always'" appearance="outline" fxFlex="100%"
                        style="padding-left: 10px; padding-right: 10px; width: 100%">
                        <mat-label>Nhập lại mật khẩu</mat-label>
                        <input matInput [type]="hide ? 'password' : 'text'" formControlName="confirmPassword">
                        <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        <mat-error *ngIf="resetPasswordForm.get('confirmPassword').hasError('required')">
                            Bắt buộc
                        </mat-error>
                        <mat-error *ngIf="resetPasswordForm.hasError('confirmPasswordValidator','confirmPassword')">
                            Mật khẩu nhập lại không khớp
                        </mat-error>
                    </mat-form-field>
                </div>
                <button (click)="reset()" type="submit" [disabled]="resetPasswordForm.invalid"
                    class="w-full text-center py-3 rounded bg-green text-white hover:bg-green-dark focus:outline-none my-1"
                    style="color: white;background-color: black;">Đặt lại mật khẩu</button>
            </form>
        </div>
    </div>
</div>