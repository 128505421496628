<div class="login-form w3_form">
     <div class="login w3_login">
          <h2 class="login-header w3_header">Đăng nhập</h2>
          <div class="w3l_grid">
               <form [formGroup]="newForm" (ngSubmit)="onSubmit(newForm.value)" class="login-container" action="#"
                    method="post">
                    <input type="email" placeholder="Email" Name="Email" formControlName="userName" required="">
                    <input type="password" placeholder="Password" formControlName="passWord" Name="password"
                         required="">
                    <a href="#exampleModal" data-toggle="modal">Quên mật khẩu?</a>
                    <input type="submit" value="Đăng nhập">
               </form>
               <div class="bottom-text w3_bottom_text mt-8">
                    <p>Bạn chưa có tài khoản? Hãy<a [routerLink]="'/register'">đăng ký</a></p>
                    <!-- <p>Đăng nhập bằng Google</p>
      <button (click)="signInWithGoogle()" class="btn btn-social-icon btn-google">
          <span class="fa fa-google"></span>
     </button> -->
               </div>
          </div>
     </div>
</div>
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     style="z-index: 1200; margin-top: 150px" aria-hidden="true">
     <div class="modal-dialog" role="document">
          <div class="modal-content">
               <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Quên mật khẩu?</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                         <span aria-hidden="true">&times;</span>
                    </button>
               </div>
               <div class="modal-body">
                    <p>Nhập chính xác email để nhận đường dẫn thiết lập lại mật khẩu</p>
                    <div class="form-group">
                         <input type="email" class="form-control" placeholder="Email" [(ngModel)]="resetPasswordEmail"
                              (ngModelChange)="checkValidEmail($event)">
                    </div>
                    <small *ngIf="!resetPasswordEmail" class="text-danger">*Vui lòng nhập chính xác địa chỉ
                         email*</small>
                    <small *ngIf="!isValidEmail && resetPasswordEmail" class="text-danger">*Định dạng email không
                         đúng*</small>
               </div>
               <div class="modal-footer">
                    <button id="closeBtn" type="button" class="btn btn-secondary" data-dismiss="modal">Đóng</button>
                    <button type="button" class="btn btn-primary" (click)="confirmToSend()">Gửi</button>
               </div>
          </div>
     </div>
</div>