<!-- Title page -->
<section class="bg-img1 txt-center p-lr-15 p-tb-92" style="background-image: url('assets/images/bg-02.jpg');">
    <h2 class="ltext-105 cl0 txt-center">
        Về chúng tôi
    </h2>
</section>	
<!-- Content page -->
<section class="bg0 p-t-75 p-b-120">
    <div class="container">
        <div class="row p-b-148">
            <div class="col-md-7 col-lg-8">
                <div class="p-t-7 p-r-85 p-r-15-lg p-r-0-md mt-4">
                    <h3 class="mtext-111 cl2 p-b-16">
                        Câu chuyện về chúng tôi
                    </h3>
                    <p class="stext-113 cl6 p-b-26">
                        ECOLIFE là nền tảng thương mại điện tử uy tín, cung cấp các dịch vụ hỗ trợ và liên kết cho người bán và người mua đồ cũ. Chúng mình tạo ra một không gian thanh lý và kí gửi đồ second hand chất lượng cao, giá cả hợp lý, và đa dạng về loại hình. Đồng thời góp phần nâng cao nhận thức của giới trẻ về bảo vệ môi trường, bằng cách giúp tăng vòng đời của các sản phẩm, giảm thiểu rác thải và chất đốt. Hãy cùng ECOLIFE hướng đến một cuộc sống xanh, tiết kiệm, và bền vững nhé!
                    </p>
                    
                </div>
            </div>
            <div class="col-11 col-md-5 col-lg-4 m-lr-auto">
                <div class="how-bor1 ">
                    <div class="hov-img0">
                        <img src="assets/images/about-01.jpg" alt="IMG">
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="order-md-2 col-md-7 col-lg-8 p-b-30">
                <div class="p-t-7 p-l-85 p-l-15-lg p-l-0-md">
                    <h3 class="mtext-111 cl2 p-b-16">
                        Sứ mệnh của chúng tôi
                    </h3>
                    <p class="stext-113 cl6 p-b-26">
                        Theo Thredup: URL Thredup, mua một chiếc áo khoác 2hand thay vì mới có thể tiết kiệm 23 pound CO2, 700 gallon nước và 1.2 kWh điện.
                    </p>
                    <p class="stext-113 cl6 p-b-26">
                        Một nghiên cứu của Ellen  MacArthur Foundation : URL Ellen  MacArthur Foundation cho thấy, nếu mọi  người ở UK sử  dụng đồ 2hand thay  vì mới trong vòng  1 năm, lượng khí thải  carbon sẽ giảm 2.7 triệu tấn, tương đương với việc loại bỏ 1 triệu chiếc xe khỏi đường.
                    </p>
                </div>
            </div>
            <div class="order-md-1 col-11 col-md-5 col-lg-4 m-lr-auto p-b-30">
                <div class="how-bor2">
                    <div class="hov-img0">
                        <img src="assets/images/about-02.jpg" alt="IMG">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>