<div class="container">
  <div class="bread-crumb flex-w p-l-25 p-r-15 p-t-30 p-lr-0-lg">
    <a href="index.html" class="stext-109 cl8 hov-cl1 trans-04">
      Trang chủ
      <i class="fa fa-angle-right m-l-9 m-r-10" aria-hidden="true"></i>
    </a>
    <a href="product.html" class="stext-109 cl8 hov-cl1 trans-04">
      Tài khoản
    </a>
  </div>
</div>
<div class="container">
  <div class="main-body">
    <div class="row">
      <div class="col-lg-4">
        <div class="card" style="height: 94%;">
          <div class="card-body">
            <div class="d-flex flex-column align-items-center text-center">
              <div class="mt-3">
                <h4>{{this.user.firstName+" "+this.user.lastName}}</h4>
                <p class="text-muted font-size-sm">{{this.user.address}}</p>
              </div>
            </div>
            <hr class="my-4">
          </div>
        </div>
      </div>
      <div class="col-lg-8">
        <div class="card">
          <div class="card-body">
            <div class="row mb-3">
              <div class="col-sm-3">
                <h6 class="mb-0">Họ</h6>
              </div>
              <div class="col-sm-9 text-secondary">
                <input type="text" class="form-control" [(ngModel)]="this.user.firstName" [value]="this.user.firstName">
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-3">
                <h6 class="mb-0">Tên</h6>
              </div>
              <div class="col-sm-9 text-secondary">
                <input type="text" class="form-control" [(ngModel)]="this.user.lastName" [value]="this.user.lastName">
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-3">
                <h6 class="mb-0">Email</h6>
              </div>
              <div class="col-sm-9 text-secondary">
                <input type="text" class="form-control" [(ngModel)]="this.user.email" [value]="this.user.email">
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-3">
                <h6 class="mb-0">Điện thoại</h6>
              </div>
              <div class="col-sm-9 text-secondary">
                <input type="text" class="form-control" [(ngModel)]="this.user.phoneNumber" [value]="this.user.phoneNumber">
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-3">
                <h6 class="mb-0">Địa chỉ</h6>
              </div>
              <div class="col-sm-9 text-secondary">
                <input type="text" class="form-control"[(ngModel)]="this.user.address" [value]="this.user.address">
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-3">
                <h6 class="mb-0">Mật khẩu hiện tại</h6>
              </div>
              <div class="col-sm-9 text-secondary">
                <input type="text" class="form-control"[(ngModel)]="PasswordCurrent">
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-3">
                <h6 class="mb-0">Mật khẩu mới</h6>
              </div>
              <div class="col-sm-9 text-secondary">
                <input type="text" class="form-control"[(ngModel)]="PasswordNew">
              </div>
            </div>
            <div class="row">
              <div class="col-sm-3"></div>
              <div class="col-sm-9 text-secondary">
                <input type="button" class="btn btn-primary px-4" value="Lưu" (click)="Submit()">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
