<!-- breadcrumb -->
<div class="container">
    <div class="bread-crumb flex-w p-l-25 p-r-15 p-t-30 p-lr-0-lg">
        <a href="index.html" class="stext-109 cl8 hov-cl1 trans-04">
            Trang chủ
            <i class="fa fa-angle-right m-l-9 m-r-10" aria-hidden="true"></i>
        </a>
        <a href="product.html" class="stext-109 cl8 hov-cl1 trans-04">
            Lịch sử mua hàng
        </a>
    </div>
</div>
<div class="main-container container">
    <div class="row">
        <!--Middle Part Start-->
        <div id="content" class="col-sm-12">
            <div class="table-responsive">
                <table class="table table-bordered table-hover">
                    <thead>
                        <tr>
                            <td class="text-center">Mã đơn hàng</td>
                            <td class="text-center">Ngày mua</td>
                            <td class="text-center">Tổng tiền</td>
                            <td class="text-center">Trạng thái</td>
                            <td class="text-center">Hình thức thanh toán</td>
                            <td class="text-center">Xem chi tiết</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of list_hoadon">
                            <td class="text-center">#{{item.id}}</td>
                            <td class="text-center">{{item.createdAt|date: 'dd/MM/yyyy HH:mm'}}</td>
                            <td class="text-center">{{item.totalPrice|number}}</td>
                            <td class="text-center">
                                <span *ngIf="item.status==0">
                                    Chưa xác nhận
                                </span>
                                <span *ngIf="item.status==1">
                                    Đã xác nhận
                                </span>
                                <span *ngIf="item.status==2">
                                    Hoàn thành
                                </span>
                                <span *ngIf="item.status==3">
                                    Đã huỷ
                                </span>
                            </td>
                            <td class="text-center">
                                <span *ngIf="item.typePayment==0">
                                    Thanh toán COD
                                </span>
                                <span *ngIf="item.typePayment==1">
                                    Thanh toán Online(VnPay)
                                </span>
                            </td>
                            <td class="text-center"><a [routerLink]="'/bill/'+item.id"><mat-icon>visibility</mat-icon></a></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
