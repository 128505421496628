<!-- breadcrumb -->
<div class="container">
    <div class="bread-crumb flex-w p-l-25 p-r-15 p-t-30 p-lr-0-lg">
        <a href="index.html" class="stext-109 cl8 hov-cl1 trans-04">
            Trang chủ
            <i class="fa fa-angle-right m-l-9 m-r-10" aria-hidden="true"></i>
        </a>
        <a href="product.html" class="stext-109 cl8 hov-cl1 trans-04">
            Danh sách sản phẩm yêu thích
            <i class="fa fa-angle-right m-l-9 m-r-10" aria-hidden="true"></i>
        </a>
    </div>
</div>
<div class="main-container container">
    <div class="table-responsive">
        <table class="table table-bordered table-hover">
            <thead>
                <tr>
                    <td class="text-left">Tên sản phẩm</td>
                    <td class="text-right">Giá</td>
                    <td>Xoá</td>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of list_sanphamyeuthich">
                    <td class="text-left">
                        <span class=" text-hover" [routerLink]="'/product/'+item.prodId">
                            {{item.name}} 
                        </span>
                    </td>
                    <td class="text-right">{{item.price|number}}</td>
                    <td><button (click)="deleteSanPham(item)"><mat-icon>delete</mat-icon></button></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>