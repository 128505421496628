import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-loginfacebook',
  templateUrl: './loginfacebook.component.html',
  styleUrls: ['./loginfacebook.component.scss']
})
export class LoginfacebookComponent implements OnInit {
  constructor() { }
  ngOnInit(): void {
  }
}
