<section class="section-slide">
    <div class="wrap-slick1">
        <div class="slick1">
            <div class="item-slick1" style="background-image: url(../../../assets/images/slide-winter.jpg);">
                <div class="container h-full">
                    <div class="flex-col-r-m h-full p-t-100 p-b-30 respon5">
                        <div class="layer-slick1 animated visible-false" data-appear="fadeInDown" data-delay="0">
                            <span class="ltext-101 cl0 respon2 text-shadow">
                                Bộ sưu tập đông 2023
                            </span>
                        </div>
                        <div class="layer-slick1 animated visible-false" data-appear="fadeInUp" data-delay="800">
                            <h2 class="ltext-201 cl0 p-t-19 p-b-43 respon1 text-shadow">
                                Winter Collection
                            </h2>
                        </div>

                    </div>
                </div>
            </div>
            <div class="item-slick1" style="background-image: url(../../../assets/images/slide-couple.jpg);">
                <div class="container h-full">
                    <div class="flex-col-r-m h-full p-t-100 p-b-30 respon5">
                        <div class="layer-slick1 animated visible-false" data-appear="rollIn" data-delay="0">
                            <span class="ltext-101 cl0 respon2 text-shadow">
                              Bộ sưu tập dành cho cặp đôi
                            </span>
                        </div>
                        <div class="layer-slick1 animated visible-false" data-appear="lightSpeedIn" data-delay="800">
                            <h2 class="ltext-201 cl0 p-t-19 p-b-43 respon1 text-shadow">
                                Couple Collection
                            </h2>
                        </div>

                    </div>
                </div>
            </div>
            <div class="item-slick1" style="background-image: url(../../../assets/images/slide-fall.jpg);">
                <div class="container h-full">
                    <div class="flex-col-r-m h-full p-t-100 p-b-30 respon5">
                        <div class="layer-slick1 animated visible-false" data-appear="rotateInDownLeft" data-delay="0">
                            <span class="ltext-101 cl0 respon2 text-shadow">
                              Bộ sưu tập thu 2023
                            </span>
                        </div>
                        <div class="layer-slick1 animated visible-false" data-appear="rotateInUpRight" data-delay="800">
                            <h2 class="ltext-201 cl0 p-t-19 p-b-43 respon1 text-shadow">
                               Fall Collection
                            </h2>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
