<footer class="bg3 p-t-75 p-b-32 mt-3">
  <div class="container">
    <div class="row">
      <div class="col-sm-6 col-lg-3 p-b-50">
        <h4 class="stext-301 cl5 p-b-30">Liên hệ</h4>
        <p class="stext-107 cl5 size-201">
          Có bất cứ câu hỏi nào, hãy cho chúng tôi biết
        </p>
        <div class="p-t-27">
          <a
            href="https://www.facebook.com/profile.php?id=61551011734893&mibextid=LQQJ4d"
            class="fs-18 cl5 hov-cl1 trans-04 m-r-16"
          >
            <i class="fa fa-facebook"></i>
          </a>
          <a href="#" class="fs-18 cl5 hov-cl1 trans-04 m-r-16">
            <i class="fa fa-instagram"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="p-t-40">
      <div class="flex-c-m flex-w p-b-18">
        <a href="#" class="m-all-1">
          <img src="assets/images/icons/icon-pay-01.png" alt="ICON-PAY" />
        </a>
        <a href="#" class="m-all-1">
          <img src="assets/images/icons/icon-pay-02.png" alt="ICON-PAY" />
        </a>
        <a href="#" class="m-all-1">
          <img src="assets/images/icons/icon-pay-03.png" alt="ICON-PAY" />
        </a>
        <a href="#" class="m-all-1">
          <img src="assets/images/icons/icon-pay-04.png" alt="ICON-PAY" />
        </a>
        <a href="#" class="m-all-1">
          <img src="assets/images/icons/icon-pay-05.png" alt="ICON-PAY" />
        </a>
      </div>
      <p class="stext-107 cl5 txt-center">
        <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
        Copyright &copy;
        <script>
          document.write(new Date().getFullYear());
        </script>
        All rights reserved | This template is made with
        <i class="fa fa-heart-o" aria-hidden="true"></i> by
        <a href="https://colorlib.com" target="_blank">Colorlib</a>
        <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
      </p>
    </div>
  </div>
</footer>
<!-- Back to top -->
<div class="btn-back-to-top" id="myBtn">
  <span class="symbol-btn-back-to-top">
    <i class="zmdi zmdi-chevron-up"></i>
  </span>
</div>
