<!-- Header -->
<header class="header-v4">
    <!-- Header desktop -->
    <div class="container-menu-desktop">
        <!-- Topbar -->
        <div class="top-bar">
            <div class="content-topbar flex-sb-m h-full container">
                <div class="left-top-bar">
                    Miễn phí ship với đơn hàng trên 100,000 VNĐ
                </div>
                <div class="right-top-bar flex-w h-full">
                    <a [routerLink]="['/contact']" class="flex-c-m trans-04 p-lr-25">
                        Trợ giúp
                    </a>
                    <a [routerLink]="['/info_user']" class="flex-c-m trans-04 p-lr-25" *ngIf="this.user!=null">
                        Xin chào {{this.user.firstName+" "+this.user.lastName}}
                    </a>
                    <a [routerLink]="['/history']" class="flex-c-m trans-04 p-lr-25" *ngIf="this.user!=null">
                      Lịch sử mua hàng
                    </a>
                    <a href="/login" class="flex-c-m trans-04 p-lr-25" *ngIf="this.user==null">
                        Đăng nhập
                    </a>
                    <a (click)="logout()" href="#" class="flex-c-m trans-04 p-lr-25" *ngIf="this.user!=null">
                        Đăng xuất
                    </a>
                </div>
            </div>
        </div>
        <div class="wrap-menu-desktop how-shadow1">
            <nav class="limiter-menu-desktop container">
                <!-- Logo desktop -->
                <a routerLink="/" class="logo">
                    <img src="assets/images/icons/logo-05.png" alt="IMG-LOGO">
                </a>
                <!-- Menu desktop -->
                <div class="menu-desktop">
                    <ul class="main-menu">
                        <li>
                            <a routerLink = "/">Trang chủ</a>
                        </li>
                        <li>
                            <a routerLink ="/shop">Sản phẩm</a>
                        </li>
                        <li>
                            <a routerLink = "/blog">Blog</a>
                        </li>
                        <li>
                            <a routerLink = "/about">Thông tin</a>
                        </li>
                    </ul>
                </div>
                <!-- Icon header -->
                <div class="wrap-icon-header flex-w flex-r-m">
                    <!-- <div class="icon-header-item cl2 hov-cl1 trans-04 p-l-22 p-r-11 js-show-modal-search">
                        <i class="zmdi zmdi-search"></i>
                    </div> -->
                    <div class="cart">
                      <a [routerLink]="['/checkout']">
                        <img src="assets/images/icons/cart.png" class="img-fluid" alt="">
                        <i class="ti-shopping-cart"></i>
                      </a>
                    </div>
                    <span class="cart_qty_cls">{{(items$ | async).length}}</span>
                    <div class="like">
                      <a [routerLink]="['/like']">
                        <img src="assets/images/icons/love.png" class="img-fluid" alt="">
                        <i class="ti-shopping-cart"></i>
                      </a>
                    </div>
                    <span class="cart_qty_cls">{{(items1$ | async).length}}</span>
                </div>
            </nav>
        </div>
    </div>
    <!-- Header Mobile -->
    <div class="wrap-header-mobile">
        <!-- Logo moblie -->
        <div class="logo-mobile">
            <a href="index.html"><img src="assets/images/icons/logo-05.png" alt="IMG-LOGO"></a>
        </div>
        <!-- Icon header -->
        <div class="wrap-icon-header flex-w flex-r-m m-r-15">
            <div class="icon-header-item cl2 hov-cl1 trans-04 p-r-11 js-show-modal-search">
                <i class="zmdi zmdi-search"></i>
            </div>
            <div class="icon-header-item cl2 hov-cl1 trans-04 p-r-11 p-l-10 icon-header-noti js-show-cart" data-notify="2">
                <i class="zmdi zmdi-shopping-cart"></i>
            </div>
            <a href="#" class="dis-block icon-header-item cl2 hov-cl1 trans-04 p-r-11 p-l-10 icon-header-noti" data-notify="0">
                <i class="zmdi zmdi-favorite-outline"></i>
            </a>
        </div>
        <!-- Button show menu -->
        <div class="btn-show-menu-mobile hamburger hamburger--squeeze">
            <span class="hamburger-box">
                <span class="hamburger-inner"></span>
            </span>
        </div>
    </div>
    <!-- Menu Mobile -->
    <div class="menu-mobile">
        <ul class="topbar-mobile">
            <li>
                <div class="left-top-bar">
                    Miễn phí ship với đơn hàng trên 100,000 VNĐ
                </div>
            </li>
            <li>
                <div class="right-top-bar flex-w h-full">
                    <a href="#" class="flex-c-m p-lr-10 trans-04">
                        Trợ giúp
                    </a>
                    <a href="#" class="flex-c-m p-lr-10 trans-04">
                        Tài khoản của tôi
                    </a>
                </div>
            </li>
        </ul>
        <ul class="main-menu-m">
            <li>
                <a href="index.html">Trang chủ</a>
            </li>
            <li>
                <a href="product.html">Sản phẩm</a>
            </li>
            <li>
                <a href="shoping-cart.html" class="label1 rs1" data-label1="hot">Features</a>
            </li>
            <li>
                <a href="blog.html">Blog</a>
            </li>
            <li>
                <a href="about.html">Thông tin</a>
            </li>
            <li>
                <a href="contact.html">Liên hệ</a>
            </li>
        </ul>
    </div>
    <!-- Modal Search -->
    <div class="modal-search-header flex-c-m trans-04 js-hide-modal-search">
        <div class="container-search-header">
            <button class="flex-c-m btn-hide-modal-search trans-04 js-hide-modal-search">
                <img src="assets/images/icons/icon-close2.png" alt="CLOSE">
            </button>
            <form class="wrap-search-header flex-w p-l-15">
                <button class="flex-c-m trans-04">
                    <i class="zmdi zmdi-search"></i>
                </button>
                <input class="plh3" type="text" name="search" placeholder="Search...">
            </form>
        </div>
    </div>
</header>
